import React from "react";

function AccessibilityStatement() {
  return (
    <div style={styles.accessibilityStatementStyle}>
      <h1 style={styles.headingStyle}>Moonryse Accessibility Statement</h1>

      <div>
        <h2 style={styles.headingStyle}>Introduction:</h2>
        <p style={styles.bodyTextStyle}>
          We are committed to ensuring that our mobile app is accessible to all
          users, including those with disabilities.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Accessibility Commitment:</h2>
        <p style={styles.bodyTextStyle}>
          Moonryse is dedicated to providing an accessible and inclusive app
          experience for all users.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Standards and Guidelines:</h2>
        <p style={styles.bodyTextStyle}>
          Our app strives to meet the Web Content Accessibility Guidelines
          (WCAG) 2 Level AA standards.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Accessibility Features:</h2>
        <ul style={styles.bodyTextStyle}>
          <li>Screen reader compatibility</li>
          <li>Voice command support</li>
          <li>Adjustable font sizes</li>
        </ul>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Testing and Compliance:</h2>
        <p style={styles.bodyTextStyle}>
          Our app undergoes regular accessibility testing and evaluation.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Ongoing Improvements:</h2>
        <p style={styles.bodyTextStyle}>
          We continuously work to improve accessibility based on user feedback
          and evolving standards.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Contact Information:</h2>
        <p style={styles.bodyTextStyle}>
          For accessibility concerns, please contact our support team at
          davis@moonryse.io.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Feedback and Reporting:</h2>
        <p style={styles.bodyTextStyle}>
          We encourage users to report accessibility issues so that we can
          address them promptly.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Accessibility Roadmap:</h2>
        <p style={styles.bodyTextStyle}>
          We are actively planning future accessibility enhancements to make our
          app even more inclusive.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Legal Compliance:</h2>
        <p style={styles.bodyTextStyle}>
          Moonryse complies with the Americans with Disabilities Act (ADA).
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Date of Last Update:</h2>
        <p style={styles.bodyTextStyle}>
          This Accessibility Statement was last updated on 9/8/23.
        </p>
      </div>

      <div>
        <h2 style={styles.headingStyle}>Conclusion:</h2>
        <p style={styles.bodyTextStyle}>
          Moonryse is committed to creating an accessible and inclusive app
          environment for all our users.
        </p>
      </div>
    </div>
  );
}

const styles = {
  accessibilityStatementStyle: {
    maxWidth: 800,
    margin: "0 auto",
    padding: 20,
  },
  headingStyle: {
    fontSize: 24,
    fontWeight: "bold",
    margin: "10px 0",
  },
  bodyTextStyle: {
    fontSize: 16,
    margin: "10px 0",
  },
};

export default AccessibilityStatement;
