import React, { useEffect, useRef, useState, useCallback } from "react";

function AnimatedTyping({ textToAnimate, fontColor }) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [text, setText] = useState("");
  const [cursorColor, setCursorColor] = useState("transparent");
  const [messageIndex, setMessageIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [timeouts, setTimeouts] = useState({
    cursorTimeout: undefined,
    typingTimeout: undefined,
    firstNewLineTimeout: undefined,
    secondNewLineTimeout: undefined,
  });

  const textRef = useRef(text);
  textRef.current = text;

  const cursorColorRef = useRef(cursorColor);
  cursorColorRef.current = cursorColor;

  const messageIndexRef = useRef(messageIndex);
  messageIndexRef.current = messageIndex;

  const textIndexRef = useRef(textIndex);
  textIndexRef.current = textIndex;

  const timeoutsRef = useRef(timeouts);
  timeoutsRef.current = timeouts;

  const reverse = useRef(false);

  let typingAnimation = useCallback(() => {
    // if you are not done with the current text, move on to the next letter and recurse
    if (
      textIndexRef.current >= 0 &&
      textIndexRef.current < textToAnimate[messageIndexRef.current].length &&
      !reverse.current
    ) {
      setText(
        textRef.current +
          textToAnimate[messageIndexRef.current].charAt(textIndexRef.current)
      );
      setTextIndex(textIndexRef.current + 1);

      let updatedTimeouts = { ...timeoutsRef.current };
      clearTimeout(updatedTimeouts.typingTimeout);
      updatedTimeouts.typingTimeout = setTimeout(typingAnimation, 110);
      setTimeouts(updatedTimeouts);
    }

    // we are reversing, take one letter off the phrase
    else if (textIndexRef.current >= 0 && reverse.current) {
      setText(textRef.current.slice(0, textIndexRef.current));
      setTextIndex(textIndexRef.current - 1);

      let updatedTimeouts = { ...timeoutsRef.current };
      clearTimeout(updatedTimeouts.typingTimeout);
      updatedTimeouts.typingTimeout = setTimeout(typingAnimation, 70);
      setTimeouts(updatedTimeouts);
    }

    // reverse the process and start deleting the word
    else if (
      // messageIndexRef.current + 1 < textToAnimate.length &&
      messageIndexRef.current < textToAnimate.length &&
      textIndexRef.current >= 1 &&
      !reverse.current
    ) {
      reverse.current = !reverse.current;

      setTextIndex(textIndexRef.current - 1);

      let updatedTimeouts = { ...timeoutsRef.current };
      clearTimeout(updatedTimeouts.typingTimeout);
      updatedTimeouts.typingTimeout = setTimeout(typingAnimation, 2000);
      setTimeouts(updatedTimeouts);
    }

    // deleted entire phrase, start building next phrase
    else if (
      messageIndexRef.current + 1 <= textToAnimate.length &&
      textIndexRef.current >= -1 &&
      reverse.current
    ) {
      reverse.current = false;

      setTextIndex(textIndexRef.current + 1);

      if (messageIndexRef.current + 1 === textToAnimate.length) {
        setMessageIndex(0);
      } else {
        setMessageIndex(messageIndexRef.current + 1);
      }

      let updatedTimeouts = { ...timeoutsRef.current };
      clearTimeout(updatedTimeouts.typingTimeout);
      updatedTimeouts.typingTimeout = setTimeout(typingAnimation, 200);
      setTimeouts(updatedTimeouts);
    }

    // NOT USED ANYMORE
    // you finished all phrases and all characters, set timeout
    else {
      // setTimeout(() => {
      //   clearInterval(timeoutsRef.current.cursorTimeout);
      //   setCursorColor("transparent");
      // }, 5000);
      clearInterval(timeoutsRef.current.cursorTimeout);

      setText("");
      textRef.current = "";
      setMessageIndex(0);
      messageIndexRef.current = 0;
      setTextIndex(-1);
      textIndexRef.current = -1;
      setTimeouts({
        cursorTimeout: undefined,
        typingTimeout: undefined,
        firstNewLineTimeout: undefined,
        secondNewLineTimeout: undefined,
      });
      timeoutsRef.current = {
        cursorTimeout: undefined,
        typingTimeout: undefined,
        firstNewLineTimeout: undefined,
        secondNewLineTimeout: undefined,
      };
      reverse.current = false;
    }
  }, [textToAnimate]);

  let cursorAnimation = () => {
    if (cursorColorRef.current === "transparent") {
      setCursorColor("#000000");
    } else {
      setCursorColor("transparent");
    }
  };

  useEffect(() => {
    let updatedTimeouts = { ...timeoutsRef.current };
    clearTimeout(updatedTimeouts.typingTimeout);
    updatedTimeouts.typingTimeout = setTimeout(typingAnimation, 500);
    clearInterval(updatedTimeouts.cursorTimeout);
    updatedTimeouts.cursorTimeout = setInterval(cursorAnimation, 500);
    setTimeouts(updatedTimeouts);

    return () => {
      clearTimeout(timeoutsRef.current.typingTimeout);
      clearTimeout(timeoutsRef.current.firstNewLineTimeout);
      clearTimeout(timeoutsRef.current.secondNewLineTimeout);
      clearInterval(timeoutsRef.current.cursorTimeout);
    };
  }, [typingAnimation, textToAnimate]);

  return (
    <div className="flex flex-wrap justify-center items-center">
      {fontColor === "white" ? (
        <p className="inline text-center text-2xl pb-3 text-white">
          {text}
          <p className="inline text-3xl pb-3" style={{ color: cursorColor }}>
            {"|"}
          </p>
        </p>
      ) : (
        <p
          className={
            isMobile
              ? "inline text-center text-2xl pb-3 font-bold leading-tight"
              : "inline text-center text-4xl pb-3 font-bold leading-tight"
          }
        >
          {text}
          <p
            className={
              isMobile ? "inline text-3xl pb-3" : "inline text-5xl pb-3"
            }
            style={{ color: cursorColor }}
          >
            {"|"}
          </p>
        </p>
      )}
    </div>
  );
}

export default AnimatedTyping;
