import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";

import { LandingPage, TermsOfService } from "./components";
import AccessibilityStatement from "./components/AccessibilityStatement";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/accessibility" element={<AccessibilityStatement />} />
    </Routes>
  );
};

export default App;
