import React, { useEffect, useState } from "react";
import logo from "../assets/logo_black.png";
import davisProfile from "../assets/davis_profile.jpg";
import josieProfile from "../assets/josie_profile.png";
import addieProfile from "../assets/addie_profile.png";
import jennaProfile from "../assets/jenna_profile.png";
import tessaProfile from "../assets/tessa_profile.png";

// import screenshot1 from "../assets/screenshots/1athlete.png";
// import screenshot2 from "../assets/screenshots/2athlete.png";
// import screenshot4 from "../assets/screenshots/4athlete.png";
// import screenshot6 from "../assets/screenshots/6athlete.png";

import moon from "../assets/moon.png";

import AnimatedTyping from "./AnimatedTyping";

import { useNavigate } from "react-router-dom";

const testimonials = [
  {
    quote:
      '"The app has helped our team to be able to communicate about hard things. It is an outlet to talk about our mental health when we don\'t always get the chance to do so out on the sand."',
    quoteBy: "Tessa Gallo",
    image: tessaProfile,
  },
  {
    quote:
      '"I have found so much inspiration and motivation from the responses I read from my teammates and just other users…The app has created a unified space that we can all share which has brought us together."',
    quoteBy: "Addie Bounds",
    image: addieProfile,
  },
  {
    quote:
      '"The app has had a super positive impact on our team in that it has allowed us a designated platform to discuss and share our mental health."',
    quoteBy: "Jenna Drobeck",
    image: jennaProfile,
  },
  {
    quote:
      '"Moonryse is a controlled, consistent, and safe space to build team trust and vulnerability off the court."',
    quoteBy: "Josie Ulrich",
    image: josieProfile,
  },
  {
    quote:
      "\"It's a quick way that I can center myself on the day and reflect on what's going on in my life. I enjoy seeing everyone's mood emoji each day and reading their answers for the daily prompts. It makes me feel connected to others!\"",
    quoteBy: "Tessa Gallo",
    image: tessaProfile,
  },
  {
    quote:
      '"I appreciate that Moonryse is tailored to each team and is adaptable to the goals of the individuals and program. I love that it is inviting, user-friendly, and something I look forward to using everyday."',
    quoteBy: "Josie Ulrich",
    image: josieProfile,
  },
  {
    quote:
      '"It helps us prepare for tournaments by reflecting on the things we need to do going in and what we can do post tournament to improve and get better!"',
    quoteBy: "Tessa Gallo",
    image: tessaProfile,
  },
];

function LandingPage() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const appStoreLink = !isIOS
    ? "https://play.google.com/store/apps/details?id=io.moonryse&pli=1"
    : "https://apps.apple.com/app/moonryse/id1667938362";

  const [getAppHovered, setGetAppHovered] = useState(false);
  const [aboutHovered, setAboutHovered] = useState(false);
  const [teamHovered, setTeamHovered] = useState(false);
  const [backToTopHovered, setBackToTopHovered] = useState(false);
  const [testimonialIndex, setTestimonialIndex] = useState(0);

  const [showQRCode, setShowQRCode] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setTestimonialIndex((prev) => (prev + 1) % testimonials.length);
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center overflow-hidden">
      {/* Home screen */}
      <div
        id="home"
        className="relative w-screen h-screen overflow-hidden"
        // style={{ backgroundColor: "#eae8e0" }}
        style={{ backgroundColor: "#faf5ea" }}
      >
        <img
          src={moon}
          alt="logo"
          width={isMobile ? 40 : 60}
          style={{
            marginLeft: isMobile ? 30 : 68,
            marginTop: isMobile ? 25 : 45,
          }}
        />

        <div
          style={{
            paddingRight: 17,
            paddingLeft: 17,
            paddingBottom: 8,
            paddingTop: 8,
            position: "absolute",
            right: isMobile ? 10 : 68,
            top: isMobile ? 30 : 50,
            fontSize: 23,
            borderColor: "#000000",
            borderWidth: 2,
            backgroundColor: getAppHovered ? "#FFE453" : undefined,
            cursor: "pointer",
            borderRadius: 30,
            zIndex: 1,
          }}
          onClick={() => {
            if (isMobile) {
              window.location.href = appStoreLink;
            } else {
              setShowQRCode(true);
            }
          }}
          onMouseEnter={() => setGetAppHovered(true)}
          onMouseLeave={() => setGetAppHovered(false)}
        >
          Get Moonryse
        </div>

        {showQRCode && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 2,
            }}
          >
            <div
              style={{
                position: "relative",
                backgroundColor: "#FFE453",
                width: "30%",
                height: "50%",
                minHeight: 450,
                minWidth: 450,
                borderRadius: 200,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  marginBottom: 10,
                  fontSize: 30,
                  cursor: "pointer",
                }}
                onClick={() => setShowQRCode(false)}
              >
                X
              </div>
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(
                  "https://apps.apple.com/app/moonryse/id1667938362"
                )}`}
                alt="QR Code"
              />
              <p
                style={{
                  fontSize: 28,
                  marginTop: 10,
                  width: "65%",
                  textAlign: "center",
                }}
              >
                Scan the QR code to download the app
              </p>
            </div>
          </div>
        )}

        <div className="absolute flex flex-col justify-center items-center top-0 bottom-0 right-0 left-0">
          <img
            src={logo}
            width={isMobile ? "280px" : "470px"}
            alt={"logo"}
            className="mb-5"
          />

          <div className="w-full h-1/3 md:w-1/2 md:h-1/6 flex flex-col items-center justify-center">
            <AnimatedTyping
              textToAnimate={[
                "Moonryse is an innovative mental health and wellness app for athletes.",
                "To reduce the number of athletes struggling in silence.",
                "To give enhance the new style of coaching - holistic coaching.",
                "To build stronger team culture.",
                "To give teams a performance advantage.",
              ]}
            />
          </div>

          <div
            style={{
              paddingRight: 25,
              paddingLeft: 25,
              paddingBottom: 8,
              paddingTop: 8,
              position: "absolute",
              bottom: isMobile ? 50 : 100,
              fontSize: 23,
              borderColor: "#000000",
              borderWidth: 2,
              backgroundColor: aboutHovered ? "#FFE453" : undefined,
              cursor: "pointer",
              borderRadius: 30,
              zIndex: 1,
            }}
            onClick={() =>
              window.scrollTo({
                top: document.getElementById("about").offsetTop,
                behavior: "smooth",
              })
            }
            onMouseEnter={() => setAboutHovered(true)}
            onMouseLeave={() => setAboutHovered(false)}
          >
            About
          </div>

          <p></p>
        </div>
      </div>

      {/* About screen */}
      <div
        id="about"
        className="w-screen h-screen overflow-hidden relative"
        style={{ backgroundColor: "#faf5ea" }}
      >
        <div
          style={{
            border: "none",
            borderTop: "2px dashed black",
            height: "2px",
            width: "100%",
            margin: "0",
            padding: "0",
            lineHeight: "0",
            borderSpacing: "30px",
          }}
        ></div>

        <div className="w-screen h-screen overflow-hidden flex flex-col items-center justify-center">
          <p style={{ fontSize: isMobile ? 32 : 46 }}>About:</p>
          <p
            className="md:text-xl text-lg md:w-1/2 w-full text-center md:leading-10 mt-4"
            style={{ fontSize: isMobile ? 24 : 32 }}
          >
            Moonryse is an advanced solution that provides a team's first step
            in their mental health and wellness journey - awareness. Our goal is
            to reduce the number of athletes struggling in silence, and to arm
            coaches with the tools they need for a holistic coaching style.
          </p>

          {/* <h1 style={{ fontSize: 46, marginTop: 50 }}>Team:</h1>
          <div className="flex flex-row items-center justify-center mt-5">
            <div className="flex flex-col items-center justify-ceter md:mx-10 mx-5">
              <a
                href="https://www.linkedin.com/in/davis-ulrich/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={davisProfile}
                  className="rounded-full hover:animate-spin-slow"
                  width={isMobile ? "150px" : "75px"}
                  alt="davis profile"
                />
              </a>
              <p className="md:text-xl text-lg mt-5">Davis Ulrich</p>

              <p className="md:text-ll text-2xs mt-2">CEO & Founder</p>
              <a href="mailto:davis@moonryse.io">
                <p className="md:text-ll text-2xs mt-1 opacity-70">
                  davis@moonryse.io
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center justify-ceter md:mx-10">
              <img
                src={josieProfile}
                className="rounded-full hover:animate-spin-slow"
                width={isMobile ? "150px" : "75px"}
                alt="josie profile"
              />
              <p className="md:text-xl text-lg mt-5">Josie Ulrich</p>
              <p className="md:text-ll text-2xs mt-2">
                Ambassador & D1 Athlete
              </p>
              <p className="md:text-ll text-2xs mt-1 opacity-0">
                josie@moonryse.io
              </p>
            </div>
          </div> */}

          <div
            style={{
              paddingRight: 25,
              paddingLeft: 25,
              paddingBottom: 8,
              paddingTop: 8,
              position: "absolute",
              bottom: 50,
              fontSize: 23,
              borderColor: "#000000",
              borderWidth: 2,
              backgroundColor: teamHovered ? "#FFE453" : undefined,
              cursor: "pointer",
              borderRadius: 30,
              zIndex: 1,
            }}
            onClick={() =>
              window.scrollTo({
                // top: document.getElementById("screenshots").offsetTop,
                top: document.getElementById("testimonials").offsetTop,
                behavior: "smooth",
              })
            }
            onMouseEnter={() => setTeamHovered(true)}
            onMouseLeave={() => setTeamHovered(false)}
          >
            {/* The app */}
            Testimonials
          </div>
        </div>
      </div>

      {/* Screenshot screen */}
      {/* {!isMobile && (
        <div
          id="screenshots"
          className="w-screen h-screen overflow-hidden relative"
          style={{ backgroundColor: "#faf5ea" }}
        >
          <div
            style={{
              border: "none",
              borderTop: "2px dashed black",
              height: "2px",
              width: "100%",
              margin: "0",
              padding: "0",
              lineHeight: "0",
              borderSpacing: "30px",
            }}
          ></div>

          <div className="w-screen h-screen overflow-hidden flex flex-col items-center justify-center">
            <p style={{ fontSize: isMobile ? 32 : 46 }}>The app:</p>
            <div className="flex items-center space-between pb-20 mt-10">
              
              <img
                src={screenshot1}
                className="rounded-2xl hover:zoom mr-20"
                width={"215px"}
                alt="screenshot1"
              />
              <img
                src={screenshot4}
                className="rounded-2xl hover:zoom mr-20"
                width={"215px"}
                alt="screenshot4"
              />

              <img
                src={screenshot2}
                className="rounded-2xl hover:zoom mr-20"
                width={"215px"}
                alt="screenshot2"
              />

              <img
                src={screenshot6}
                className="rounded-2xl hover:zoom"
                width={"215px"}
                alt="screenshot6"
              />
            </div>

            <div
              style={{
                paddingRight: 25,
                paddingLeft: 25,
                paddingBottom: 8,
                paddingTop: 8,
                position: "absolute",
                bottom: 50,
                fontSize: 23,
                borderColor: "#000000",
                borderWidth: 2,
                backgroundColor: teamHovered ? "#FFE453" : undefined,
                cursor: "pointer",
                borderRadius: 30,
                zIndex: 1,
              }}
              onClick={() =>
                window.scrollTo({
                  top: document.getElementById("testimonials").offsetTop,
                  behavior: "smooth",
                })
              }
              onMouseEnter={() => setTeamHovered(true)}
              onMouseLeave={() => setTeamHovered(false)}
            >
              Testimonials
            </div>
          </div>
        </div>
      )} */}

      {/* Testimonials screen */}
      <div
        id="testimonials"
        className="w-screen h-screen overflow-hidden relative"
        style={{ backgroundColor: "#faf5ea" }}
      >
        <div
          style={{
            border: "none",
            borderTop: "2px dashed black",
            height: "2px",
            width: "100%",
            margin: "0",
            padding: "0",
            lineHeight: "0",
            borderSpacing: "30px",
          }}
        ></div>

        <div className="w-screen h-screen overflow-hidden flex flex-col items-center justify-center">
          <p style={{ fontSize: isMobile ? 32 : 46 }}>Testimonials:</p>
          <div className="testimonials-content">
            <p
              className="md:text-xl text-lg md:w-2/3 w-full text-center md:leading-10 mt-4"
              style={{
                fontSize: isMobile ? 22 : 28,
                fontStyle: "italic",
                marginRight: 6,
                marginLeft: 6,
              }}
            >
              {testimonials[testimonialIndex].quote}
            </p>
            <div className="flex flex-row items-center mt-5">
              {/* <p className="md:text-2xl text-xl mr-3">-</p> */}
              <img
                src={testimonials[testimonialIndex].image}
                className="rounded-full"
                width={isMobile ? "52px" : "65px"}
                alt="testimonial profile"
              />
              <p className="ml-4" style={{ fontSize: isMobile ? 18 : 26 }}>
                {testimonials[testimonialIndex].quoteBy}
              </p>
            </div>
          </div>

          <div
            style={{
              paddingRight: 25,
              paddingLeft: 25,
              paddingBottom: 8,
              paddingTop: 8,
              position: "absolute",
              bottom: 50,
              fontSize: 23,
              borderColor: "#000000",
              borderWidth: 2,
              backgroundColor: teamHovered ? "#FFE453" : undefined,
              cursor: "pointer",
              borderRadius: 30,
              zIndex: 1,
            }}
            onClick={() =>
              window.scrollTo({
                top: document.getElementById("team").offsetTop,
                behavior: "smooth",
              })
            }
            onMouseEnter={() => setTeamHovered(true)}
            onMouseLeave={() => setTeamHovered(false)}
          >
            Team
          </div>
        </div>
      </div>

      {/* Team screen */}
      <div
        id="team"
        className="w-screen h-screen overflow-hidden relative"
        style={{ backgroundColor: "#faf5ea" }}
      >
        <div
          style={{
            border: "none",
            borderTop: "2px dashed black",
            height: "2px",
            width: "100%",
            margin: "0",
            padding: "0",
            lineHeight: "0",
            borderSpacing: "30px",
          }}
        ></div>

        <div className="w-screen h-screen overflow-hidden flex flex-col items-center justify-center">
          <h1 style={{ fontSize: 46, marginTop: 50 }}>Team:</h1>
          <div className="flex flex-row items-center justify-between mt-5">
            <div className="flex flex-col items-center justify-ceter md:mx-10 mx-5">
              <a
                href="https://www.linkedin.com/in/davis-ulrich/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={davisProfile}
                  className="rounded-full hover:animate-spin-slow"
                  width={isMobile ? "115px" : "150px"}
                  alt="davis profile"
                />
              </a>
              <p style={{ fontSize: isMobile ? 20 : 28, marginTop: 10 }}>
                Davis Ulrich
              </p>

              <p
                style={{
                  fontSize: isMobile ? 16 : 20,
                  marginTop: 10,
                  opacity: 0.8,
                }}
              >
                CEO & Founder
              </p>
              <a href="mailto:davis@moonryse.io">
                <p
                  style={{
                    fontSize: isMobile ? 14 : 18,
                    marginTop: 5,
                    opacity: 0.7,
                  }}
                >
                  davis@moonryse.io
                </p>
              </a>
            </div>
            <div className="flex flex-col items-center justify-ceter md:mx-10">
              <img
                src={josieProfile}
                className="rounded-full hover:animate-spin-slow"
                width={isMobile ? "115px" : "150px"}
                alt="josie profile"
              />
              <p style={{ fontSize: isMobile ? 20 : 28, marginTop: 10 }}>
                Josie Ulrich
              </p>
              <p
                style={{
                  fontSize: isMobile ? 16 : 20,
                  marginTop: 10,
                  opacity: 0.8,
                  textAlign: "center",
                  width: isMobile ? 95 : undefined,
                }}
              >
                Ambassador & D1 Athlete
              </p>
              {!isMobile && (
                <p
                  style={{
                    opacity: 0,
                  }}
                >
                  davis@moonryse.io
                </p>
              )}
            </div>
          </div>

          <div
            style={{
              paddingRight: 25,
              paddingLeft: 25,
              paddingBottom: 8,
              paddingTop: 8,
              position: "absolute",
              bottom: 50,
              fontSize: 23,
              borderColor: "#000000",
              borderWidth: 2,
              backgroundColor: backToTopHovered ? "#FFE453" : undefined,
              cursor: backToTopHovered ? "pointer" : undefined,
              borderRadius: 30,
              zIndex: 1,
            }}
            onClick={() =>
              window.scrollTo({
                top: document.getElementById("home").offsetTop,
                behavior: "smooth",
              })
            }
            onMouseEnter={() => setBackToTopHovered(true)}
            onMouseLeave={() => setBackToTopHovered(false)}
          >
            Back to top
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            right: isMobile ? 5 : 50,
            bottom: isMobile ? 10 : 50,
            opacity: 0.4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontSize: isMobile ? 12 : 20,
            }}
          >
            © 2023 Moonryse LLC
          </p>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <p
              style={{
                fontSize: isMobile ? 10 : 14,
                cursor: "pointer",
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/privacy");
              }}
            >
              Privacy
            </p>

            <p
              style={{
                fontSize: isMobile ? 10 : 14,
                marginLeft: 25,
                cursor: "pointer",
              }}
              onClick={() => {
                window.scrollTo(0, 0);
                navigate("/terms");
              }}
            >
              Terms
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;

// GRAVEYARD:

// About:
// A highly integrated team off the court performs on the
//             court, and this is acheived through building strong connections with
//             teammates, captains, coaches, and the sports psychologist. Prompts
//             are sent out to the team before gameday and before the training
//             week, which elicit productive thinking and allow the athletes a
//             place to share and feel heard.We provide a private community and team prompts
// that elicit productive thinking and strengthen the connections
// between teammates.

// Animated Typing:
// V1
// "To build an open and supportive culture.",
//                 "Productive conversations, productive sharing",
//                 "To give everyone a voice.",
//                 "Team dynamics can make it or break it.",
//                 "Mental health is a silent killer.",
//                 "See how your team is doing.",
//                 "with Moonryse prompts and check-ins.",
//                 "A mental health app for collegiate athletes.",

// V2
// textToAnimate={[
//   "Mental Health Social Media.",
//   "A place to talk about how you're feeling.",
//   "See how your friends are really feeling.",
//   "Comment for support and validation.",
//   "Learn from each other's experiences and perspectives.",
//   "Check in with friends and family.",
//   "Mental Health Social Media.",
// ]}

// V3
// "A mental health platform for athletes.",
//                 "A safe space to talk and be heard.",
//                 "To encourage productive reflections.",
//                 "To set proper intentions.",
//                 "To get in the right headstate.",
//                 "As individuals and as a team.",
//                 "To keep mental health front and center.",
//                 "To build team cohesion.",
//                 "Mental health can make it or break it.",
//                 "So let's make it,",
//                 "Make it happen on Moonryse.",

// V4
// "A wellness app for athletes.",
//                 "Routine reflection, interaction, and progress tracking.",
//                 "A team safe space.",
//                 "A structured journal with prompts.",
//                 "Goal setting and progress tracking with coaches.",
//                 "Data and insights on wellness metrics.",
//                 "Stronger athletes, stronger individuals.",
//                 "Moonryse builds team culture.",

// Description V1:

// Moonryse is a <strong>wellness app for athletes</strong> that builds
//           stronger culture through routine{" "}
//           <strong>reflection, communication, and progress tracking</strong>.
//           In a fun way, not a boring way.

// Moonryse is a team wellness center that builds stronger culture and
//             gives you a competitive edge. Our product includes a private social
//             platform for the team, a guided journal, goal-oriented progress
//             tracking, and data on team wellness factors.
